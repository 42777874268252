import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { useLatest } from "react-use";
import {
  ApplyItemActionInput,
  AskWhen,
  AskWhom,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import {
  DecisionProcess_NextAndPreviousItemsDocument,
  DecisionProcess_NextAndPreviousItemsQuery,
  DecisionProcess_NextAndPreviousItemsQueryVariables,
  DecisionProcess_NextPossibleItemDocument,
  DecisionProcess_NextPossibleItemQuery,
  DecisionProcess_NextPossibleItemQueryVariables,
} from "./useDecisionProcess.generated";

const ON_PRESELECTION_CHANGE_REFETCH_QUERIES = [
  namedOperations.Query.DecisionProcess_NextAndPreviousItems,
];

export type ProcessState =
  | { status: "idle"; itemId: null }
  | { status: "starting"; itemId: null }
  | { status: "running"; itemId: string }
  | { status: "finished"; itemId: null };

interface DecisionProcess {
  viewerDecisionRole?: AskWhom;
  decisionContext: AskWhen;
  state: ProcessState;

  start: () => Promise<boolean>;
  next: (() => Promise<void>) | undefined;
  previous: (() => Promise<void>) | undefined;
  close: () => void;
}

interface Args {
  projectId: string | null;
  docId: string;
  applyItemActions?: Array<ApplyItemActionInput>;
  itemUuidSeed: string;
  embeddedMode: boolean;
  viewerDecisionRole?: AskWhom;
  decisionContext: AskWhen;
  contractorId?: string;
  onClose?: () => Promise<unknown>;
}

const INITIAL_STATE: ProcessState = {
  status: "idle",
  itemId: null,
};

export function useDecisionProcess({
  projectId,
  docId,
  applyItemActions,
  itemUuidSeed,
  embeddedMode,
  viewerDecisionRole,
  decisionContext,
  contractorId,
  onClose,
}: Args): DecisionProcess {
  const [firstItemId, setFirstItemId] = React.useState<string | null>(null);
  const [state, setState] = React.useState<ProcessState>(INITIAL_STATE);
  const setItemId = React.useCallback(
    (itemId: string) => {
      if (state.status !== "running") {
        throw new Error("Can only set itemId on 'running' decision process");
      }
      setState({
        status: "running",
        itemId: itemId,
      });
    },
    [state.status]
  );

  React.useEffect(() => {
    setState(INITIAL_STATE);
  }, [decisionContext]);

  const client = useApolloClient();

  const latestApplyItemActions = useLatest(applyItemActions);

  const fetchNextAndPreviousItems = React.useCallback(
    async (itemId: string) => {
      const { data } = await client.query<
        DecisionProcess_NextAndPreviousItemsQuery,
        DecisionProcess_NextAndPreviousItemsQueryVariables
      >({
        query: DecisionProcess_NextAndPreviousItemsDocument,
        variables: {
          docId,
          projectId,
          itemId,
          viewerDecisionRole,
          decisionContext,
          applyItemActions: latestApplyItemActions.current,
          itemUuidSeed,
          embeddedMode,
          contractorId,
        },
        fetchPolicy: "no-cache",
      });

      return data.itemNextAndPreviousDecisionItem;
    },
    [
      client,
      docId,
      projectId,
      viewerDecisionRole,
      decisionContext,
      latestApplyItemActions,
      itemUuidSeed,
      embeddedMode,
      contractorId,
    ]
  );

  const getFirstAdmittedItemId = React.useCallback(async () => {
    const { data } = await client.query<
      DecisionProcess_NextPossibleItemQuery,
      DecisionProcess_NextPossibleItemQueryVariables
    >({
      query: DecisionProcess_NextPossibleItemDocument,
      variables: {
        docId,
        projectId,
        viewerDecisionRole,
        decisionContext,
        itemId: null,
        itemPathSortable: null,
        itemUuidSeed,
        embeddedMode,
        contractorId,
      },
      fetchPolicy: "no-cache",
    });

    const nextPossibleItemId =
      data.itemNextPossibleDecisionItem.nextPossibleItem?.id;

    return nextPossibleItemId ?? null;
  }, [
    client,
    docId,
    projectId,
    viewerDecisionRole,
    decisionContext,
    itemUuidSeed,
    embeddedMode,
    contractorId,
  ]);

  const start = React.useCallback(async () => {
    setState(state => {
      if (state.status !== "idle") {
        throw new Error("Decision process already started");
      }

      return { status: "starting", itemId: null };
    });
    const itemId = await getFirstAdmittedItemId();
    if (itemId) {
      setFirstItemId(itemId);
      setState({ status: "running", itemId });

      return true;
    } else {
      setState({ status: "finished", itemId: null });
      return false;
    }
  }, [getFirstAdmittedItemId]);

  const close = React.useCallback(() => {
    setState({
      status: "idle",
      itemId: null,
    });
    onClose?.();
  }, [onClose]);

  const next = React.useCallback(async () => {
    if (!state.itemId) throw new Error("Can't get next without itemId");

    const result = await fetchNextAndPreviousItems(state.itemId);
    const nextItem = result?.nextItem;

    if (nextItem) {
      setItemId(nextItem.id);
    } else {
      setState({ status: "finished", itemId: null });
    }
  }, [fetchNextAndPreviousItems, setItemId, state.itemId]);

  const previous = React.useCallback(async () => {
    if (!state.itemId) throw new Error("Can't get previous without itemId");

    const result = await fetchNextAndPreviousItems(state.itemId);
    const previousItem = result?.previousItem;

    if (previousItem) {
      setItemId(previousItem.id);
    } else {
      close();
    }
  }, [close, fetchNextAndPreviousItems, setItemId, state.itemId]);

  return {
    viewerDecisionRole,
    decisionContext,
    state,

    start,
    close,
    next,
    previous: firstItemId === state.itemId ? undefined : previous,
  };
}
